import React from 'react'

import Layout from '../components/layout'
import CenteredContent from '../components/centered-content'

const NotFoundPage = () => (
	<Layout title="Not Found">
		<CenteredContent>
			<h1>Not found</h1>
			<p>The page does not exist.</p>
		</CenteredContent>
	</Layout>
)

export default NotFoundPage
